import styled from 'styled-components'


const PhotoFrameWrapper = styled.div`
    img {
        cursor: pointer;
    }
`


const s = {
    PhotoFrameWrapper,
};


export default s;